/* src/styles.css */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.album-container {
  padding: 15px; /* Reduced padding */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.2s;
  height: 300px; /* Reduced height */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center;
}

.album-container:hover {
  transform: scale(1.05);
}

.album-image {
  width: 200px; /* Adjusted width */
  height: 200; /* Adjusted height */
  border-radius: 8px;
  object-fit: cover;
}

.album-name {
  font-size: 16px; /* Adjusted font size */
  font-weight: bold;
  color: #333;
  margin-top: 10px;
}
